import React, { useState, useEffect, useRef } from 'react'
import Creatable from 'react-select/creatable'
import { useForm } from 'react-hook-form'
import PropTypes from 'prop-types'
import { Button, Notification, TimePicker } from 'react-rainbow-components'
import { useTranslation } from 'react-i18next'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import { fi, sv, enUS } from 'date-fns/locale'
import Select from 'react-select'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowCircleLeft } from '@fortawesome/free-solid-svg-icons'
import moment from 'moment'
import * as API from '../../utilities/api'
import './styles.scss'
import SuccessMessage from '../SuccessMessage'
import ErrorMessage from '../ErrorMessage'

const customStyles = {
  menu: (provided) => ({
    ...provided,
    zIndex: 3,
  }),
  control: (base, state) => ({
    ...base,
    borderColor: state.isFocused ? '#48544c' : base.borderColor,
    boxShadow: state.isFocused ? '0 0 0 1px #48544c' : base.boxShadow,
    '&:hover': {
      borderColor: state.isFocused ? '#48544c;' : base.borderColor,
    },
  }),
  input: (base) => ({
    ...base,
    padding: '0px',
  }),
  option: (base, state) => ({
    ...base,
    backgroundColor: state.isSelected ? '#adc5b5' : '#fff',
  }),
}

const EntryForm = ({
  redirect,
  previousEvents = [],
  relatedTargetUnits,
  card,
  handleSetCard,
  handleNavigateBack,
  errors,
  setErrors,
}) => {
  const defaultTargetUnit =
    relatedTargetUnits && relatedTargetUnits.length === 1
      ? relatedTargetUnits[0]
      : null
  const { register, handleSubmit, setValue, watch, reset } = useForm({
    defaultValues: {
      childrenAmount: 0,
      eventDate: new Date(),
      targetUnit: defaultTargetUnit ? defaultTargetUnit.value : null,
    },
  })
  const { i18n, t } = useTranslation()

  const [success, setSuccess] = useState(false)
  const [loading, setLoading] = useState(false)
  const [doRedirect, setDoRedirect] = useState(true)
  const [showInfo, setShowInfo] = useState(true)
  const [newEventName, setNewEventName] = useState(null)
  const [selectedEventInfo, setSelectedEventInfo] = useState(null)
  const [showMonetaryValue, setShowMonetaryValue] = useState(false)
  const selectedTargetUnit =
    watch(['targetUnit']) && watch(['targetUnit']).targetUnit
  const [targetUnitEvents, setTargetUnitEvents] = useState(previousEvents)
  const eventSelectRef = useRef(
    register(
      { name: 'event' },
      {
        maxLength: 100,
      }
    )
  )
  const targetUnitRef = useRef(
    register(
      { name: 'targetUnit' },
      {
        maxLength: 300,
      }
    )
  )
  const scheduleSelectRef = useRef(
    register({ name: 'schedule' }, { maxLength: 100 })
  )
  // Watch form value for eventDate and pass it to date picker as current date
  const currentDate = watch(['eventDate']) && watch(['eventDate']).eventDate
  const currentTime = watch(['eventTime']) && watch(['eventTime']).eventTime

  // Save card data to local storage
  useEffect(() => {
    if (card) {
      localStorage.setItem('cardData', JSON.stringify(card))
    }
  }, [card])
  // Retrieve card data from local storage on mount
  useEffect(() => {
    const storedCard = localStorage.getItem('cardData')
    if (storedCard) {
      handleSetCard(JSON.parse(storedCard))
    }
  }, [handleSetCard])

  const numChildrenOnProfile = card?.cardProfile?.numberOfMinors || 0

  // Get operator related events whenever selected operator changes
  // This is only valid for ticket dealer operators, who need to
  // select operators
  useEffect(() => {
    setErrors({ ...errors, operator: null })
    const getTargetUnitEvents = async () => {
      try {
        const res = await API.GET('api/v1/events/', {
          allPages: true,
          targetUnit: selectedTargetUnit,
        })
        if (res && res.data && res.data.results && selectedTargetUnit) {
          const { data } = res
          const events = data.results
            .filter((event) => event.active)
            .map((event) => ({
              value: event.id,
              label: event.name,
              eventInfo: event.eventInfo,
              showMonetaryValue: event.showMonetaryValue,
            }))
            .slice(-30)
            .reverse()
          setTargetUnitEvents(events)
        } else {
          setTargetUnitEvents([])
        }
      } catch (err) {
        setTargetUnitEvents([])
      }
    }
    getTargetUnitEvents()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedTargetUnit])

  const handleReset = () => {
    reset()

    eventSelectRef.current.clearValue()
    if (relatedTargetUnits && relatedTargetUnits.length > 1) {
      targetUnitRef.current.clearValue()
    }
    setNewEventName(null)
    setSelectedEventInfo(null)
    setValue('eventDate', new Date())
    setValue('eventTime', null)
  }

  useEffect(() => {
    const setTimeoutAndRedirect = () => {
      // Show success message for 3 seconds before redirecting
      const timer = setTimeout(() => {
        setSuccess(false)
        // Clear card to be able to redirect after submitting data
        if (doRedirect) {
          handleSetCard(null)
          redirect()
        } else {
          handleReset()
        }
      }, 3000)
      return () => clearTimeout(timer)
    }

    if (success) {
      setTimeoutAndRedirect()
    }
  }, [success, setSuccess, doRedirect, handleSetCard, redirect, handleReset])

  const onSubmit = async (formData) => {
    setDoRedirect(false)
    try {
      const data = {
        ...formData,
        card: card.id,
        newEvent: newEventName,
        eventDate: moment(formData.eventDate).format('YYYY-MM-DD'),
      }
      setErrors({})
      setLoading(true)
      const apiResp = await API.POST('api/v1/entries/', data)
      if (apiResp && apiResp.status === 201) {
        setSuccess(true)
        setLoading(false)
      } else {
        setErrors({ entry: apiResp.data?.errors.join(', ') })
        setLoading(false)
      }
    } catch (e) {
      console.error(e)
      setErrors({ entry: t('entry.someError') })
      setLoading(false)
    }
  }

  const getLocale = () => {
    switch (i18n.language) {
      case 'fi':
        return fi
      case 'sv':
        return sv
      case 'en':
        return enUS
      default:
        return fi
    }
  }

  return (
    <div className="entry-form">
      {success && <SuccessMessage msg={t('entry.savedSuccessfully')} />}
      <ErrorMessage msg={errors.entry} />
      <h1>{t('entry.addEntry')}</h1>
      {card && <h3>{card.cardNumber}</h3>}
      <form onSubmit={handleSubmit(onSubmit)}>
        {relatedTargetUnits && relatedTargetUnits.length >= 1 && (
          <>
            <label>
              {t('entry.selectUnit')}
              <Select
                inputId="targetUnit"
                defaultValue={defaultTargetUnit}
                className="creatable-input"
                styles={customStyles}
                isClearable={relatedTargetUnits.length > 1}
                placeholder={t('entry.office')}
                aria-label={t('entry.office')}
                options={relatedTargetUnits}
                onChange={(selected) => {
                  eventSelectRef.current.clearValue()
                  setValue('event', null)
                  setTargetUnitEvents([])
                  setSelectedEventInfo(null)
                  setNewEventName(null)
                  setValue('targetUnit', selected ? selected.value : null)
                  if (!selected) {
                    setValue('eventDate', new Date())
                    setValue('eventTime', null)
                  }
                }}
                ref={targetUnitRef}
              />
            </label>

            {errors && errors.target_unit && (
              <div className="rainbow-flex rainbow-flex_column">
                <div className="rainbow-p-bottom_x-small">
                  <Notification
                    title="Valitse toimipiste"
                    className="entry-form-notification"
                    style={{ width: '100%' }}
                    description="Toimipiste on pakollinen tieto."
                    icon="error"
                    onRequestClose={() =>
                      setErrors({ ...errors, targetUnit: null })
                    }
                  />
                </div>
              </div>
            )}
          </>
        )}

        <label>
          {t('entry.selectEvent')}
          <Creatable
            inputId="event"
            className="creatable-input"
            isClearable
            styles={customStyles}
            options={targetUnitEvents}
            placeholder={t('entry.nameOfEvent')}
            aria-label={t('entry.nameOfEvent')}
            noOptionsMessage={() => t('entry.noEvents')}
            formatCreateLabel={(inputValue) => `Luo ${inputValue}`}
            onChange={(selected, event) => {
              if (!selected) {
                setValue('eventDate', new Date())
                setValue('eventTime', null)
                setSelectedEventInfo(null)
                setShowMonetaryValue(false)
              } else if (event && event.action === 'create-option') {
                setValue('event', null)
                setNewEventName(selected.value)
                setSelectedEventInfo(null)
                setShowMonetaryValue(false)
              } else {
                setValue('event', selected.value)
                setSelectedEventInfo(selected.eventInfo)
                setShowMonetaryValue(selected.showMonetaryValue)
              }
            }}
            ref={eventSelectRef}
          />
        </label>
        {['Y', 'YM', 'Z'].includes(card.cardType) && (
          <label htmlFor="cardClientAmount">
            {t('entry.numberOfClientCards')}
            <input
              type="number"
              min="0"
              max="100"
              placeholder={t('entry.numberOfClientCards')}
              name="cardClientAmount"
              ref={register({ required: true, maxLength: 100 })}
              id="cardClientAmount"
            />
          </label>
        )}
        {['Y', 'YM', 'Z'].includes(card.cardType) && showInfo && (
          <div className="rainbow-flex rainbow-flex_column">
            <div className="rainbow-p-bottom_x-small">
              <Notification
                title={t('entry.communityCardTitle')}
                className="entry-form-notification"
                style={{ width: '100%' }}
                description={t('entry.communityCardInfo')}
                icon="info"
                onRequestClose={() => setShowInfo(false)}
              />
            </div>
          </div>
        )}

        <label htmlFor="numChildren">
          {t('entry.amountOfChildren')}
          <input
            type="number"
            min="0"
            max="100"
            placeholder={t('entry.amountOfChildren')}
            name="childrenAmount"
            ref={register({ required: true, maxLength: 100 })}
            id="numChildren"
          />
        </label>
        {['A', 'AM', 'X'].includes(card.cardType) &&
          showInfo &&
          numChildrenOnProfile >= 0 && (
            <div className="rainbow-flex rainbow-flex_column">
              <div className="rainbow-p-bottom_x-small">
                <Notification
                  title={t('entry.amountOfChildrenOrGrandchildren')}
                  className="entry-form-notification"
                  style={{ width: '100%' }}
                  description={t('entry.clientsAmountOfChildren', {
                    numChildrenOnProfile,
                  })}
                  icon="info"
                  onRequestClose={() => setShowInfo(false)}
                />
              </div>
            </div>
          )}

        {showMonetaryValue && (
          <label htmlFor="monetaryValue">
            {t('entry.monetaryValue')}
            <input
              type="number"
              min="0.00"
              max="10000000.00"
              step="0.01"
              placeholder="€"
              name="monetaryValue"
              ref={register({ required: true, maxLength: 100 })}
              id="monetaryValue"
            />
          </label>
        )}

        {!!selectedEventInfo && selectedEventInfo.schedule?.length ? (
          <label>
            {t('entry.selectTimeOfEvent')}
            <Select
              inputId="schedule"
              className="input"
              styles={customStyles}
              isClearable
              placeholder={t('entry.selectTimeOfEvent')}
              aria-label="schedule"
              options={selectedEventInfo?.schedule || []}
              onChange={(selected) => {
                setValue('schedule', selected ? selected.value : null)
                if (selected) {
                  const [datePart, timeRange] = selected.value.split(' ')
                  const eventDate = moment(datePart, 'DD.MM.YYYY').toDate()
                  const [startTime] = timeRange.split(' - ')
                  setValue('eventDate', eventDate)
                  setValue('eventTime', startTime)
                } else {
                  setValue('eventDate', null)
                  setValue('eventTime', null)
                }
              }}
              ref={scheduleSelectRef}
            />
          </label>
        ) : (
          <>
            <div>
              <label>
                {t('entry.dateOfEvent')}
                <DatePicker
                  id="datepicker-1"
                  value={new Date(currentDate)}
                  onChange={(value) => setValue('eventDate', value)}
                  selected={currentDate}
                  locale={getLocale()}
                  dateFormat="dd.MM.yyyy"
                  ref={() => register({ name: 'eventDate' })}
                  showMonthDropdown
                  showYearDropdown
                  dropdownMode="select"
                  withPortal
                />
              </label>
            </div>

            <div>
              <label>
                {t('entry.timeOfEvent')}
                <TimePicker
                  id="timepicker-1"
                  value={currentTime}
                  onChange={(value) => setValue('eventTime', value)}
                  formatStyle="medium"
                  locale={getLocale()}
                  okLabel="Ok"
                  cancelLabel={t('entry.cancel')}
                  ref={() => register({ name: 'eventTime' })}
                  hour24
                />
              </label>
            </div>
          </>
        )}

        <Button
          type="submit"
          variant="brand"
          style={{
            width: '100%',
            marginTop: '1rem',
            lineHeight: '1.5rem',
            padding: '0.5rem 0',
          }}
          label={t('save')}
          isLoading={loading}
        />
        <Button
          type="button"
          variant="base"
          onClick={handleNavigateBack}
          style={{ width: '100%', marginTop: '1.5rem' }}
        >
          <FontAwesomeIcon
            icon={faArrowCircleLeft}
            className="rainbow-m-right_medium"
          />
          {t('back')}
        </Button>
      </form>
    </div>
  )
}

EntryForm.propTypes = {
  redirect: PropTypes.func.isRequired,
  previousEvents: PropTypes.arrayOf(PropTypes.shape({})),
  card: PropTypes.shape({
    id: PropTypes.number,
    cardNumber: PropTypes.string,
    cardType: PropTypes.string,
    cardProfile: PropTypes.shape({
      numberOfMinors: PropTypes.number,
    }),
  }),
  handleSetCard: PropTypes.func.isRequired,
  handleNavigateBack: PropTypes.func.isRequired,
  relatedTargetUnits: PropTypes.instanceOf(Array).isRequired,
  errors: PropTypes.instanceOf(Object).isRequired,
  setErrors: PropTypes.func.isRequired,
}

EntryForm.defaultProps = {
  previousEvents: [],
  card: null,
}

export default EntryForm
